<template>
    <div id="contenedor">
        <div id="borde">
            <div id="header">
                <div class="header_icons">
                    <router-link to="/pruebate"><img @click="soundButton.play()" src="../assets/icono_atras.svg" alt="Vidas" class="icono"></router-link>
                </div>
                <div class="header_icons">
                    <img src="../assets/icono_speaker.svg" alt="Sonido" class="icono">
                    <img @click="soundButton.play()" src="../assets/icono_home.svg" alt="Inicio" class="icono">
                </div>
            </div>

            <div id="titulo">Ranking</div>

            <div id="filtros">
                <div @click="soundButton.play(); ranking_diario()" :class="{ filtro: true, selected: selected === 'today' }">Diario</div>
                <div @click="soundButton.play(); ranking_week()" :class="{ filtro: true, selected: selected === 'week' }">Semanal</div>
                <div @click="soundButton.play(); ranking_all()" :class="{ filtro: true, selected: selected === 'all' }">Histórico</div>
            </div>

            <div id="lista_container">
                <ul id="lista" class="primeralista">
                    <li v-for="(rank, index) in ranking_1" :key="rank">
                        <div class="list_id">{{ index + 1 }}</div>
                        <div class="list_content">
                            <div class="list_content_name">{{ rank["Nombre"] }}</div>
                            <div class="list_content_value">{{ rank["Puntaje"] }}</div>
                        </div>
                    </li>
                </ul>

                <ul id="lista" class="segundalista">
                    <li v-for="(rank, index) in ranking_2" :key="rank">
                        <div class="list_id">{{ index + 9 }}</div>
                        <div class="list_content">
                            <div class="list_content_name">{{ rank["Nombre"] }}</div>
                            <div class="list_content_value">{{ rank["Puntaje"] }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      ranking_1: null,
      ranking_2: null,
      soundButton: document.querySelector('#soundButton'),
      selected: 'week'
    }
  },
  mounted () {
    axios
      .get('https://grantierra.looki.xyz/admin/ETCGET/?tipo=week')
      .then(response => {
        this.ranking_1 = response.data.slice(0, 8)
        this.ranking_2 = response.data.slice(8, 16)
      })
  },
  methods: {
    ranking_diario: function () {
      this.selected = 'today'
      axios
        .get('https://grantierra.looki.xyz/admin/ETCGET/?tipo=today')
        .then(response => {
          this.ranking_1 = response.data.slice(0, 8)
          this.ranking_2 = response.data.slice(8, 16)
        })
    },
    ranking_week: function () {
      this.selected = 'week'
      axios
        .get('https://grantierra.looki.xyz/admin/ETCGET/?tipo=week')
        .then(response => {
          this.ranking_1 = response.data.slice(0, 8)
          this.ranking_2 = response.data.slice(8, 16)
        })
    },
    ranking_all: function () {
      this.selected = 'all'
      axios
        .get('https://grantierra.looki.xyz/admin/ETCGET/?tipo=all')
        .then(response => {
          this.ranking_1 = response.data.slice(0, 8)
          this.ranking_2 = response.data.slice(8, 16)
        })
    }
  }
}
</script>

<style scoped>
#contenedor {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/patron_ranking.svg");
    background-color: #0C1D3E;
    background-size: cover;
    background-position: center;
    padding: 18px 20px;
}
#borde {
    border-radius: 25px;
    border: 2px solid white;
    margin: 0 auto;

    max-width: 1280px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.header_icons {
    display: flex;
}
.icono {
    width: 25px;
    height: auto;
    margin: 0px 4px;
}

#titulo {
    color: #f176f2;
    font: 80px "Bebas Neue";
}

#filtros {
    width: 40%;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
}
.filtro {
    background-color: #5f217f;
    width: 30%;
    min-width: 80px;
    border-radius: 15px;
    font: 24px "Bebas Neue";
    color: white;
}
.filtro:hover {
    cursor: pointer;
}
.selected {
    background-color: #f176f2;
}

#lista_container {
    width: 100%;
    display: flex;
    justify-content: center;
}
#lista {
    width: 95%;
    list-style-type: none;
    margin: 0px;
    padding: 0px 0px 25px;
}
.segundalista {
    display: none;
}
#lista li {
    display: flex;
    padding: 5px;
}
#lista li .list_id {
    background-color: white;
    color: #0C1D3E;
    font: 30px "Bebas Neue";
    border-radius: 5px 0px 0px 5px;
    width: 35px;
    margin: 3px 0px 3px 3px;
}
#lista.primeralista li:nth-child(-n+3) .list_id {
    background-color: #f176f2;
}
#lista li .list_content {
    background-color: #54ceea;
    color: white;
    font: 28px "Bebas Neue";

    border-radius: 5px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0px 15px;
}
#lista.primeralista li:nth-child(-n+3) .list_content {
    background-color: #5f217f;
}
#lista li .list_content_value {
    font-size: 40px;
    line-height: 1;
    color: #0C1D3E;
}
#lista.primeralista li:nth-child(-n+3) .list_content_value {
    color: white;
}

@media screen and (min-width: 800px) {
    .segundalista {
        display: block;
    }
    #lista_container {
        width: 80%;
        display: flex;
        justify-content: space-around;
    }
    #lista {
        width: 40%;
    }
    #lista li .list_id {
        font: 4vh "Bebas Neue";
        width: 50px;
    }
    #lista li .list_content {
        font: 4vh "Bebas Neue";
    }
    .filtro {
        font: 32px "Bebas Neue";
    }
}
</style>
